import { Component, OnInit, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Inject, NgZone } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ErrordialogService } from 'src/app/error-dialog/errordialog.service';


@Component({
  selector: 'app-processingfee',
  templateUrl: './processingfee.component.html',
  styleUrls: ['./processingfee.component.css']
})

export class ProcessingfeeComponent implements OnInit, AfterViewInit {
  WithdrawForm: FormGroup;
  customer_code: string;
  mobile: string;
  loanDetails: any;
  private Gateway_key = environment.key_id;
  profile_data: any;
  data: any;
  sliderControl: FormControl = new FormControl();
  constructor(private errorDialogService: ErrordialogService, private zone: NgZone,
    private elementRef: ElementRef, private bottomSheet: MatBottomSheet, private apiService: ApiService,
    private fb: FormBuilder, private router: Router, private cdRef: ChangeDetectorRef) { }


  ngOnInit(): void {
    this.apiService.getProfile().subscribe((userdata: any) => {

      if (userdata.code === "OK") {
        let profile_detail = userdata.profile;
        this.profile_data = userdata.profile;

       // this.PayForm.controls['customer_code'].setValue(profile_detail.customer_code);

      }

    });
    this.apiService.fetchLoanDetails().subscribe((data: any) => {
      if (data.code == "OK") {
        this.loanDetails = data.loan;
      }
    });
  }

  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
  }


  Goback() {

    let checkforFirstWithdraw = (this.loanDetails.payments).filter(type => type.amount < 0);
    if ((this.loanDetails.limit_product === true) && (this.loanDetails.status === 'limit_approved') || checkforFirstWithdraw.length > 0) {
      this.router.navigate(['/credit/']);
    }
    else {
      this.router.navigate(['/login/']);
    }

  }

  repay(amount_outstanding) {
    let today = new Date();

    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    let dateTime = date + ' ' + time;

    let order_id = Date.parse(dateTime)
    let forgatewayAmount = amount_outstanding * 100;
    let options = {
      "key": this.Gateway_key, // Enter the Key ID generated from the Dashboard
      "amount": forgatewayAmount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": this.profile_data.name,
      "description": 'LOC--' + this.loanDetails.loan_uid,
      "image": "",
      //"order_id": 'order_'+order_id, 
      "handler": (response) => {

        this.handle_response(response, amount_outstanding);
      },
      "prefill": {
        "name": this.profile_data.name,
        "email": this.profile_data.email,
        "contact": this.profile_data.mobile
      },
      "notes": {
        "LoanID": 'LOC--' + this.loanDetails.loan_uid
      },
      "theme": {
        "color": "#4c3196"
      }
    }



    let rzp1 = this.apiService.nativeWindow.Razorpay(options);
    rzp1.open();

  }

  handle_response(response, amount_outstanding) {

    if (typeof response.razorpay_payment_id == 'undefined' || response.razorpay_payment_id < 1) {

      this.zone.run(() => {
        this.router.navigate(['/sorry']);
      });
    } else {

      const LoanRequest = {
        loanUid: amount_outstanding,
        transaction_id: 'timestamp',
        transaction_type: 'PAY',
        customer_code: this.customer_code,
        amount: amount_outstanding
      };


      this.zone.run(() => {
        this.apiService.loanRepaid(LoanRequest).subscribe((data: any) => {
          if (data.counts.processed > 0) {
            this.apiService.changeRepayAmount(amount_outstanding);
            this.router.navigate(['/congratulation']);
          }
          else {
            let error_data = {}
            this.errorDialogService.openDialog(error_data);
          }
        });

      })
    }

  }

}

<div class="container-fluid m-0 p-0">
    <div class="curve-bg">
       <div class="row">
            <div class="col-sm-12">
                <mat-icon class="back-arrow" style="cursor: pointer;" (click)="Goback()">arrow_back</mat-icon>

            </div>
        </div>
        <div class="row text-center">
            <div class="col-md-4 offset-md-4">
    
                <div style="font-size:14px;" class="pb-2">Pay Insurance Premium Now 
                    
                    </div>
                    <div style="font-size:14px; padding: auto;color: #e43779;">&
                    
                    </div>
                    <div style="font-size:14px; padding:auto ; color: #e43779;">Unlock your Approved Credit
                    
                    </div>
                    <img src="assets/processfee.png">
                <form>
       
                <div class="d-flex align-items-center justify-content-center mt-3">
                <div class="amount-box">    <div class="p-0 due-val">
    
                    ₹ {{loanDetails?.amount_outstanding | number}}
                  </div> </div>
                </div> 
             
                <div class="container mt-4">
                    <input (click)="repay(loanDetails.amount_outstanding)"
                    class="form-control form-btn " type="button" value="Pay Now">
                </div>
                </form>
             

            </div>
        </div>
    </div>
</div>  
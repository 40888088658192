import { Component, OnInit,Inject,NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {MatBottomSheet, MatBottomSheetRef,MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';

import {MatDialog, MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-otppopup',
  templateUrl: './otppopup.component.html',
  styleUrls: ['./otppopup.component.css']
})
export class OtppopupComponent implements OnInit {
  private Gateway_key = environment.key_id;
  constructor(private zone: NgZone,private apiService: ApiService, private fb: FormBuilder,@Inject(MAT_DIALOG_DATA) public data: any,private router: Router,private _bottomSheetRef: MatDialogRef<OtppopupComponent>) { }
  profile_data:any;
  mask_mobile_no:string;
  PayForm:FormGroup;
  submitted = false;
  otpMaxLength = 6;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputClass: 'form-control form-inputs',
    inputStyles: {
      'padding':0,
      'display': 'inline-block'
    }
  };
  ngOnInit(): void {

    this.PayForm = this.fb.group({
      loanUid:[this.data.loan_id],
      transaction_id:['timestamp'],
      transaction_type:['DRAW'],
      customer_code:[this.data.customer_code],
      amount:[this.data.amount],
      otp:['', [Validators.required]]
    })

    this.mask_mobile_no = this.data.mobile.replace(this.data.mobile.substr(2, 6),"XXXXXX");

    this.apiService.getProfile().subscribe((userdata:any) =>{
     
      if(userdata.code === "OK")
      {
         
          this.profile_data = userdata.profile;
         
        
         
      }
      
    })

  }

    onOtpChange(otp)
    {
      this.PayForm.controls['otp'].setValue(otp);
    }


    VerifyOTP()
    {
      if (!this.PayForm.valid) {
        return false;
      }

      if (this.PayForm.value.otp.toString().length !== this.otpMaxLength) {
        
        return false;
      }

      const LoanRequest =  {
        loanUid: this.PayForm.value.loanUid,
        transaction_id: this.PayForm.value.transaction_id,
        transaction_type: this.PayForm.value.transaction_type,
        customer_code: this.PayForm.value.customer_code,
        amount: this.PayForm.value.amount
      };

      this.apiService.pwaVerifyOtp(this.data.mobile,this.PayForm.value.otp).subscribe((Vdata:any) =>{

        if(Vdata.profile_count > 0)
        {
          
          this.apiService.loanRepaid(LoanRequest).subscribe((Sdata:any) =>{
            this._bottomSheetRef.close();
            if(Sdata.counts.processed > 0){
              
              this.apiService.changeRepayAmount(this.data.amount);
              
              this.router.navigate(['/congo/']);
          }
          else{
            this.router.navigate(['/sorry/']);
          }
      
          },
          error => {
            this.PayForm.reset();
      })
      }
        
     })
  }

  ResendOtp()
  {
    //let requestLoad = {'mobile' : Number(this.data.mobile)}
    this.apiService.pwaRequestOtp(this.data.mobile).subscribe();
    
  }

}

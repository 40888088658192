import { Injectable } from '@angular/core';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ErrordialogComponent } from './errordialog/errordialog.component';

@Injectable({
  providedIn: 'root'
})
export class ErrordialogService {

  public isDialogOpen: Boolean = false;
    constructor(public dialog: MatDialog) { }
    openDialog(data: any):any {
        if (this.isDialogOpen) {
            return false;
        }
        this.isDialogOpen = true;
        const dialogRef = this.dialog.open(ErrordialogComponent, {
            width: '40%',
            data: data
        });

        dialogRef.afterClosed().subscribe((result: any) => {          
            this.isDialogOpen = false;
            let animal;
            animal = result;
        });
    }
}

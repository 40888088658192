import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpParams
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrordialogService } from './error-dialog/errordialog.service';
import { SpinnerService } from './services/spinner.service';
import { timer } from 'rxjs';
import { AuthService } from './auth/auth.service';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService,public errorDialogService: ErrordialogService,public spinnerService: SpinnerService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');

         if (!request.url.includes('/v1/pwa'))
        {
            let first = request.url.split('/').slice(0, 5).join('/')
            const identifier = request.url.match(/api\/(.*)$/)[0];
            let last= identifier.substring(identifier.indexOf('/') +1)

            let final_url = first+'/'+username+'/'+last;
            request = request.clone({ url: final_url });
        }

        if(this.auth.isAuthenticated())
        {
            this.spinnerService.show();
        }
       
        if (request.url.includes('/loan-status/check_point'))
        {
            console.log(request.url)
            this.spinnerService.hide();
            let change_url=request.url.substring(0, request.url.lastIndexOf("/") + 1)
            request = request.clone({ url: change_url });
            
        }
        if (token) {
            //request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
            request = request.clone({ headers: request.headers.set('x-auth-token',  token) });
           
        }
        /*if (username) {
            if(request.body)
            {
                request = request.clone({
                    body: {...request.body, username: username },
                  
                  });
            }
            else{
                request = request.clone({
                   
                    params: (request.params ? request.params : new HttpParams())
                               .set('username', username) 
                  });
            }
           
           
        }*/
        if(request.body=="FormData")
        {
            request = request.clone({ headers: request.headers.set('Content-Type', 'multipart/form-data') });
        }

         if (!request.headers.has('Content-Type')  && request.body==null) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //timer(1000).subscribe(x => {  this.spinnerService.hide(); })
                    this.spinnerService.hide();
                    event;
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let error_data = {};
              
                error_data = {
                    //reason: error && error.error && error.error.error ? error.error.error : 'Failed to connect with server',
                    
                    status: error.status
                };
               
                this.errorDialogService.openDialog(error_data);
                this.spinnerService.hide();
                return throwError(error);
            }));
    }
}

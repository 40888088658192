import { Component, OnInit,Inject,NgZone } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef,MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { PayAmountComponent } from '../pay-amount/pay-amount.component';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment';
import { ErrordialogService } from 'src/app/error-dialog/errordialog.service';
@Component({
  selector: 'app-due-payment',
  templateUrl: './due-payment.component.html',
  styleUrls: ['./due-payment.component.css']
})
export class DuePaymentComponent implements OnInit {
  private Gateway_key = environment.key_id;
  customer_code:string;
  profile_data:any;
  constructor(private errorDialogService: ErrordialogService,private zone: NgZone,@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private apiService: ApiService,private bottomSheet: MatBottomSheet,private _bottomSheetRef: MatBottomSheetRef<DuePaymentComponent>, private router: Router ) { }

  ngOnInit(): void {

    this.apiService.getProfile().subscribe((userdata:any) =>{
     
      if(userdata.code === "OK")
      {
          let profile_detail = userdata.profile;
          this.profile_data = userdata.profile;
          this.customer_code=profile_detail.customer_code;
         
      }
      
    })

  }


  repay(loan_id,total_due)
  {
      let today = new Date();

      let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      
      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      
      let dateTime = date+' '+time;

     let order_id= Date.parse(dateTime)
     let forgatewayAmount = total_due*100;
      let options = {
        "key": this.Gateway_key, // Enter the Key ID generated from the Dashboard
        "amount": forgatewayAmount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": this.profile_data.name,
        "description": 'LOC--' + this.data.loan_id,
        "image":  "",
        //"order_id": 'order_'+order_id, 
        "handler": (response) => {
         
          this.handle_response(response,loan_id,total_due); 
      },
        "prefill": {
            "name": this.profile_data.name,
            "email": this.profile_data.email,
            "contact": this.profile_data.mobile
        },
        "notes": {
          "LoanID": 'LOC--' + this.data.loan_id
        },
        "theme": {
            "color": "#4c3196"
        }
    }

    

  let rzp1 = this.apiService.nativeWindow.Razorpay(options);
  rzp1.open();
  

  }



  handle_response(response,loan_id,total_due){
    
    if (typeof response.razorpay_payment_id == 'undefined' ||  response.razorpay_payment_id < 1) {
      this._bottomSheetRef.dismiss();
      this.zone.run(() => {
        this.router.navigate(['/sorry']);
    });
    } else {
     
      const LoanRequest =  {
        loanUid: loan_id,
        transaction_id: 'timestamp',
        transaction_type: 'REPAY',
        customer_code: this.customer_code,
        amount: total_due
      };
      this._bottomSheetRef.dismiss();
  
   
        this.zone.run(() => {
          this.apiService.loanRepaid(LoanRequest).subscribe((data:any) =>{
            if(data.counts.processed > 0){
            this.apiService.changeRepayAmount(total_due);
          this.router.navigate(['/congratulation']);
            }
            else{
              let error_data = {}
              this.errorDialogService.openDialog(error_data);
            }
      });
       
      })
    }
 
  }


  congo()
  {
    this._bottomSheetRef.dismiss();
  
    this.router.navigate(['/congratulation']);
  }
  


  openBottomSheet(loan_id,total_due): void {
    this.bottomSheet.open(PayAmountComponent, {
      data: { 'loan_id':loan_id,'total_due':total_due},
    });
  }


  goBack()
  {
    this._bottomSheetRef.dismiss();
    this.router.navigate(['/available-limit']);
  }

  
}

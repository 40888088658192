import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
function _window(): any {
  // return the global native browser window object
  return window;
}
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  get nativeWindow(): any {
    return _window();
  }
  private BASE_URL = environment.apiUrl;
  private PWA_URL = environment.pwaApiUrl;

  private repaid_amount = new BehaviorSubject(0); // set default status
  currentAmount = this.repaid_amount.asObservable();

  constructor(private http: HttpClient) { }


  changeRepayAmount(repay_amt: number) {
    this.repaid_amount.next(repay_amt)
  }

  getProfile(): Observable<any> {
    return this.http.get(`${this.BASE_URL}profiles/me`, { responseType: 'json' });
  }



  requestOtp(mobile): Observable<any> {

    //https://sdk-api.staging.happy-tech.in/v1/api/demopartner/sessions/otp
    return this.http.post(`${this.BASE_URL}sessions/otp`, { sdk_user: mobile }, { responseType: 'json' });

  }
  verifyOtp(data): Observable<any> {

    return this.http.post(`${this.BASE_URL}sessions`, { sdk_user: data }, { responseType: 'json' });

  }

  fetchLoanDetails(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/loan-status`, { responseType: 'json' });
  }

  fetchLoanDetailsForCondition(): Observable<any> {
    return this.http.get(`${this.BASE_URL}loan-status/check_point`, { responseType: 'json' });
  }

  loanRepaid(data): Observable<any> {
    return this.http.post(`${this.BASE_URL}transaction`, data, { responseType: 'json' });
  }

  pwaRequestOtp(mobile: string): Observable<any> {

    // return this.http.post(`{https://sdk-api.arthapp.in/v1/pwa/otp}`, { mobile });
    return this.http.post(`${this.PWA_URL}/otp`, { mobile });
  }

  pwaVerifyOtp(mobile: string, otp: string): Observable<any> {
    return this.http.post(`${this.PWA_URL}/verify`, { mobile, otp });
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/auth/auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private _Activatedroute: ActivatedRoute,private fb: FormBuilder, private apiService: ApiService, private router: Router, public auth: AuthService) { }

  loginForm:FormGroup;
  submitted = false;
  loanDetails:any;
  ngOnInit(): void {
    let user_name = this._Activatedroute.snapshot.paramMap.get("username");
    localStorage.setItem('username',user_name);

    this.loginForm = this.fb.group({
      mobile:['',[
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10),
      ]]
    })

    if(this.auth.isAuthenticated())
    {
      this.apiService.fetchLoanDetailsForCondition().subscribe((data:any) =>{
    
        if(data.code == "OK"){
          
          this.loanDetails = data.loan;
  
          let checkforFirstWithdraw= (this.loanDetails.payments).filter(type => type.amount < 0);
  
    //(this.loanDetails.limit_product === true) && (this.loanDetails.status === 'limit_approved') ||
          if (checkforFirstWithdraw.length > 0) {
        
            this.router.navigate(['/available-limit/']);
          }
          else{
            this.router.navigate(['/credit/']);
          }  
        }
      });
    }


  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
get f() { return this.loginForm.controls }
login()
{
  this.submitted = true;
  if(this.loginForm.invalid) {
    return;
  }

  if(this.loginForm.valid){
    let logindata = this.loginForm.value;

  this.apiService.requestOtp(logindata).subscribe((data:any) =>{

   
    this.router.navigate(['/otp/',this.loginForm.value.mobile]);
  })

   
  }
}
}

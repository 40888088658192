
<div class="container-fluid">
    <div class="page-container">
  
        <div class="left-arrow">
            <a style="cursor: pointer;"><img src="../../../assets/Vector-3.svg" ></a>
        </div>

        <div class="d-flex justify-content-center p-4">
            <img src="../../../assets/Arth Repay Illustration.svg">
         </div>
         <div class="container">
            <div class="box rotate"></div>
          </div>
         <div class="heading-sorry p-2">Congratulations! </div>

         <div class="text-sorry"> ₹ {{reapy_amount}}</div>

        <div class="text-center limit-title">Has been repaid succesfully</div>
        
        <div class="text-center limit-title p-5"> Your transaction ID <br> XXXXXXXXXXXXXXXXXXXXXXXX</div>

        <div class="d-flex justify-content-center">
                       
            <input [routerLink]="['/transaction-history']"  class="form-control sorry-btn btn-sm" type="button" value="View Transaction">
           
        </div>

    </div>
</div>
<!--<div class="container-fluid m-0 p-0">

    <div class="curve-bg">
        <div class="row text-center">
            <div class="col-md-4 offset-md-4">

                <img src="../../assets/correct.png" class="img-fluid mt-3" />
               

                <h1 class="heading-title mt-3 text-center" style="font-weight: 500;font-size: 2em;">Congratulations!</h1>

                <div class="mt-4">
                    <h3 class="mb-1 heading-t2">You have successfully repaid </h3>
                    <label class="heading heading-label">₹ {{reapy_amount}}</label>
                </div>

                <div style="margin-top: 3.2rem; margin-bottom: 3.2rem;">

                    Your transaction ID <br> XXXXXXXXXXXXXXXXXXXXXXXX
                </div>

                <div class="container mt-4">
                    <input [routerLink]="['/transaction-history']" class="form-control form-btn-no-bg" type="button" value="Transaction History">


                </div>
            </div>
        </div>


    </div>
</div>-->
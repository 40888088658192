import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private route: Router) { }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    return true;
  
  }
  getToken() {
    return localStorage.getItem("token")
  }
  isLoggednIn() {
    return this.getToken() !== null;
  }
  
  logOut(){
    localStorage.removeItem('token');
    localStorage.clear();
    this.route.navigate(['/']);
  }
}

import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
@Component({
  selector: 'app-errordialog',
  templateUrl: './errordialog.component.html',
  styleUrls: ['./errordialog.component.css']
})
export class ErrordialogComponent implements OnInit {

 
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialogRef<ErrordialogComponent>, private router: Router,private auth: AuthService) {
   
   
  }


  ngOnInit(): void {
    
  }

  closeDialog()
  {
    this.dialog.close('ErrordialogComponent');
  }

  getLogin()
  {
    this.auth.logOut();
    this.dialog.close('ErrordialogComponent');
    this.router.navigate(['/login']);
  }

}

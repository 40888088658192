<div class="row">
    <div class="col-sm-12">
        <mat-icon class="back-arrow" style="cursor: pointer;" (click)="goBack()">arrow_back</mat-icon>

    </div>
</div>
<div class="row">
    <div class="col-md-12 mb-4">

        <h3 class="heading-title" style="text-align:center;"><b>Due Payment</b></h3>

        <div class="trans-box mt-4 p-4 clearfix" (click)="repay(data.loan_id,data.interest)" style="cursor: pointer; background-color: #fce8d5; color:#e43779 ; font-weight: bold;">
            <div class="float-start"> <span class="trans-comment">Pay Interest</span></div>
            <div class="float-end">₹ {{data.interest}}
                <mat-icon style="position: relative; top: 0.49rem;">keyboard_arrow_right</mat-icon>
            </div>
        </div>

        <div class="trans-box mt-4 p-4 clearfix" (click)="repay(data.loan_id,data.total_due)" style="cursor: pointer; background-color: #fce8d5; color:#e43779 ; font-weight: bold;">
            <div class="float-start"> <span class="trans-comment">Pay Total</span></div>
            <div class="float-end">₹ {{data.total_due}}
                <mat-icon style="position: relative; top: 0.49rem;">keyboard_arrow_right</mat-icon>
            </div>
        </div>
        <div (click)="openBottomSheet(data.loan_id,data.total_due)" class="trans-box mt-4 p-4 clearfix" style="cursor: pointer; background-color: #fce8d5; color:#e43779 ; font-weight: bold;">
            <div class="float-start"> <span class="trans-comment"> Pay other amount</span></div>
            <div class="float-end">
                <mat-icon style="position: relative; top: 0.49rem;">keyboard_arrow_right</mat-icon>
            </div>
        </div>



    </div>
</div>
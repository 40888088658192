import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'TimeConvert', pure: false
})
export class TimeconvertPipe implements PipeTransform {

transform(value: any): any {
    if (value === undefined) return 0;   

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
];

  let given_date = new Date(value);
  let converted_date; 
  
  if (value!="")
  { 
    converted_date = given_date.getDate()+' '+ monthNames[given_date.getMonth()]+', '+given_date.getFullYear(); 
  }
     return converted_date;
  }

}
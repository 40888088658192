import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-credit',
  templateUrl: './credit.component.html',
  styleUrls: ['./credit.component.css']
})
export class CreditComponent implements OnInit {

 constructor(private router: Router,private apiService: ApiService) { }

 loanDetails:any;
 avilableLimit: number;
 monthNames = ["January", "February", "March", "April", "May", "June",
 "July", "August", "September", "October", "November", "December"
];

valid_date: string;
customer_name:string;

  ngOnInit(): void {
    this.apiService.fetchLoanDetails().subscribe((data:any) =>{
    
      if(data.code == "OK"){
        
        this.loanDetails = data.loan;

        let checkforFirstWithdraw= (this.loanDetails.payments).filter(type => type.amount < 0);

  //(this.loanDetails.limit_product === true) && (this.loanDetails.status === 'limit_approved') ||
        if (checkforFirstWithdraw.length > 0) {
      
          this.router.navigate(['/available-limit/']);
        }
      
        this.avilableLimit = (this.loanDetails.amount_offered - this.loanDetails.amount_outstanding);
        let limit_till = new Date(this.loanDetails.limit_validity);
        if(this.loanDetails.limit_validity!=""){
          this.valid_date = this.monthNames[limit_till.getMonth()]+' '+ limit_till.getDate()+', '+limit_till.getFullYear();
        }
        
         
      }
    });

    this.apiService.getProfile().subscribe((userdata:any) =>{
     
      if(userdata.code === "OK")
      {
          let profile_detail = userdata.profile;
       
        this.customer_name= profile_detail.name;
       
         
      }
      
    })
  }
withdraw()
{
this.router.navigate(['/withdraw']);
}
}

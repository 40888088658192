<div class="container-fluid">
  <div class="page-container">

  <div class="d-flex justify-content-center p-4">
     <img src="../../../assets/ArthLogo.svg">
  </div>

  <div class="box-model mt-4">

    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column">
        <div class="p-1 limit-title">Valid Till</div>
      </div>
      <div class="d-flex flex-column">
        <div class="p-1 limit-value">{{valid_date}}</div>
      </div>
    
    </div>

      
  </div>

<div class="mt-4">
  <div
  style="font-size: 10px;width: 100%; height: 40px; position: absolute; top: 19rem; left: 12px; margin-top: -20px; line-height:19px; text-align: center;">
  <!--z-index: 999999999999999-->
  <span class="donut-circle-text">Approved Limit </span><Br />
  <span class="donut-circle-count">₹ {{loanDetails?.principal | number }}</span>

  
</div>
<canvas id="execution_status"
  style="min-height: 200px; height: 300px; max-height: 212px; max-width: 100%; position: relative; left: 14px;">
  {{ execution_status }}</canvas>

  </div>



<div class="d-flex justify-content-center">
  <div class="d-flex flex-column">
    <div class="p-1" style="color: #3E3E3E;"><img class="donut-legent-1" src="../../../assets/Ellipse.svg"> Available</div>
    <div class="p-1"><button type="button" [routerLink]="['/withdraw']" class="btn btn-limit-page">Withdraw</button></div>

    
  </div>
  <div class="d-flex flex-column">
    <div class="p-1 align-self-end" style="color: #3E3E3E;"><img class="donut-legent-2" src="../../../assets/Ellipse.svg"> Spent</div>
    <div class="p-1"><button type="button" [routerLink]="['/repay']" class="btn btn-limit-page">Repay</button></div>
    
  </div>

</div>

<div class="text-center mt-4">
<div style="transform: rotate(90deg); cursor: pointer;">
<img src="../../../assets/Vector-3.svg" [routerLink]="['/transaction-history']">
</div>

<div class="pt-2 trans-title">Transactions</div>
</div>
  </div>
</div>

<!--<div class="container-fluid m-0 p-0">

  <div class="curve-bg">
    <div class="row text-center">
      <div class="col-md-4 offset-md-4">
        <div class="mt-3">
          <div class="heading-title" style="font-size:2rem; font-weight: 500;">₹ {{(loanDetails?.principal_repaid) | number}}</div>
          <div class="mt-0">Available Limit</div>
        </div>


      </div>
    </div>

    <div class="container">
      <div class="mt-2">
        <div class="col-md-4 offset-md-4">


          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <div class="p-1 limit-title">Total Limit</div>
              <div class="p-1 limit-title">Interest Applicable</div>
              <div class="p-1 limit-title">Valid Till</div>
            </div>

            <div class="d-flex flex-column">
              <div class="p-1 limit-value">₹ {{loanDetails?.principal | number }}</div>
              <div class="p-1 limit-value">₹ {{interest_amount}} per month</div>
              <div class="p-1 limit-value">{{valid_date}}</div>
            </div>

          </div>

          <div class="container">
            <input [routerLink]="['/withdraw']" class="form-control form-btn mt-3" type="button" value="Withdraw">
          </div>

          <div class="mt-3">

            <div class="due-box">
              <div class="heading text-center p-2" style="font-size: 1.2rem; font-weight: 500;">Clear your dues</div>


              <div class="d-flex flex-row justify-content-around pb-2">
                <div class="d-flex flex-column align-items-center border-right">
                  <div class="p-0 due-title">
                    Principal
                  </div>

                  <div class="pb-3 due-val">
                    ₹ {{(loanDetails?.principal - loanDetails?.principal_repaid) | number}}
                  </div>

                  <div class="p-0 due-title">
                    Interest
                  </div>

                  <div class="p-0 due-val">₹ {{(loanDetails?.interest_charged - loanDetails?.interest_repaid) | number}}</div>
                </div>
                <div class="d-flex flex-column align-items-center" style="border-right: 2px solid #e5dede">
                  <div class="p-0">

                  </div>
                </div>
                <div class="d-flex flex-column align-items-center">

                  <div class="p-0 due-title">
                    Total
                  </div>

                  <div class="p-0 due-val">
                   
                    ₹ {{loanDetails?.amount_outstanding | number}}
                  </div>
                  <div class="pt-3">
                  
                    <input (click)="openBottomSheet(loanDetails?.amount_outstanding,loanDetails?.loan_uid,cal_interest_amount)"
                      class="form-control form-btn " type="button" value="Pay Now">
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="container mt-4">

            <input [routerLink]="['/transaction-history']" class="form-control form-btn-no-bg" type="button"
              value="Transaction History">


          </div>
        </div>
      </div>

    </div>


  </div>


</div>-->
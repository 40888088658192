import { Component , OnInit } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DuePaymentComponent } from '../due-payment/due-payment.component';
import { ApiService } from 'src/app/services/api.service';
import { timer } from 'rxjs';
import Chart from 'chart.js/auto';
@Component({
  selector: 'app-available-limit',
  templateUrl: './available-limit.component.html',
  styleUrls: ['./available-limit.component.css']
})
export class AvailableLimitComponent implements OnInit {

  avilableLimit: number;
  loanDetails:any;
  monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

valid_date: string;
interest_amount:number=0;
total_dues_cal:number=0;
cal_interest_amount:number = 0;
processingfee: number = 0;
execution_status:any;
  constructor(private bottomSheet: MatBottomSheet,private apiService: ApiService) { }

  ngOnInit(): void {
    //timer(1000).subscribe(x => { this.getLoanDetails(); })
    this.getLoanDetails();



  }
  getLoanDetails(){
  this.apiService.fetchLoanDetailsForCondition().subscribe((data:any) =>{
    
    if(data.code == "OK"){
      
      this.loanDetails = data.loan;
      this.cal_interest_amount = this.loanDetails.interest_charged - this.loanDetails.interest_repaid
    

     // this.avilableLimit = (this.loanDetails.amount_offered - this.loanDetails.amount_outstanding);
      // this.avilableLimit = this.loanDetails.principal_repaid;
      this.avilableLimit = this.loanDetails.principal;
      let limit_till = new Date(this.loanDetails.limit_validity);

      let interest_rate = Number((parseFloat(this.loanDetails.interest_rate_percent)*30).toFixed(1));
      this.interest_amount =  (interest_rate * this.avilableLimit)/100  || 0;
      this.interest_amount = Number(this.interest_amount.toFixed(2))
      //this.interest_amount =  this.avilableLimit * parseFloat(this.loanDetails.interest_rate_percent)/100 || 0;
      
      this.total_dues_cal = this.loanDetails.amount_outstanding + this.loanDetails.interest_charged;
      this.processingfee = this.loanDetails.amount_outstanding;

      if(this.loanDetails.limit_validity!=""){
        this.valid_date = this.monthNames[limit_till.getMonth()]+' '+ limit_till.getDate()+', '+limit_till.getFullYear();
      }
      
       
    const  pie_data = {
      labels: [ 'Available', 'spent'],
      datasets: [{
        label: '',
        data: [ this.loanDetails.principal_repaid, this.loanDetails.amount_outstanding],
        backgroundColor: [
          'rgba(28, 207, 201, 0.5)',
          'rgba(178, 37, 103, 0.5)'
          
        ],
      borderColor:[ '#F0F8FF' ],
      borderWidth:3,
      hoverOffset: 4
       
      }]

      
    };
    
  this.execution_status = new Chart("execution_status", {
    type: 'doughnut',
data: pie_data,
options: {

onClick(click,elements, chart){
  console.log(click)

},
  responsive: true,
  cutout: 65,

  plugins: {
    
    legend: {
      
      position: 'right',
      display: false,
      
    }
  
  }
    },
});
    }
  });
}
openBottomSheet(total_due,loan_id,cal_interest_amount): void {
  this.bottomSheet.open(DuePaymentComponent, {
    data: { 'total_due':  total_due , 'loan_id':loan_id, 'interest':cal_interest_amount},
  });
  }

}

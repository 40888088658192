<div class="container-fluid">
    <div class="page-container">
  
        <div class="left-arrow">
            <a style="cursor: pointer;"><img src="../../../assets/Vector-3.svg"></a>
        </div>

        <div class="d-flex justify-content-center p-4">
            <img src="../../../assets/cross.svg" style="width: 8rem; height: 8rem;">
         </div>

         <div class="heading-sorry p-4">Sorry!</div>

         <div class="text-sorry p-4">

            You transaction has failed,<br>
            
               Please try again 
            
        </div>

        <div class="d-flex justify-content-center mt-4">
                       
            <input [routerLink]="['/withdraw']" class="form-control sorry-btn btn-sm" type="button" value="Try Again">
           
        </div>

    </div>
</div>

<!--<div class="container-fluid m-0 p-0">

    <div class="curve-bg">
        <div class="row text-center">
            <div class="col-md-4 offset-md-4">


                <img src="../../assets/sorry.JPG" class="img-fluid mt-3" />

                <h1 class="heading-title mt-4" style="font-weight: 500;font-size: 2em;">Sorry!</h1>

                <div style="margin-top: 1.9rem; margin-bottom: 7.1rem;">

                    <h3 class="mb-0">You transaction has failed,</h3>
                    
                        <h3 class="mb-0"> Please try again </h3>
                    
                </div>

                <div class="container mt-4">
                    <input [routerLink]="['/withdraw']" class="form-control form-btn" type="button" value="Go back to Withdraw">
                </div>

            </div>
        </div>


    </div>
</div>-->
import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'happy';
  constructor(public auth: AuthService){}

  logoutUser(){
    this.auth.logOut();
  }
  
}

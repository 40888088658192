<div class="container-fluid">
  <div class="page-container">

    
<div class="text-center mt-4">
  <div style="transform: rotate(-90deg); cursor: pointer;">
  <img src="../../../assets/Vector-3.svg" [routerLink]="['/available-limit']">
  </div>
  
  <div class="pt-2 trans-title">
  Transactions
  </div>
  </div>

  <div class="trans-details mt-1">
  <div class="p-1" *ngFor="let ab of payment_details | groupBy:'date'">
    <div class="mb-2">
      <label class="date-lable">
       {{ ab.key| TimeConvert }} </label>

      <div class="mt-1 clearfix" *ngFor="let data of ab.value" style="cursor: pointer; border-bottom: 1px solid #DEDEDE;"
        [routerLink]="['/transaction-details',data.id]">
        <div class="float-start"> <span class="trans-comment-repay">{{data.type}} <br> <span class="trans-time"> 15.10</span> </span></div>
        <div class="float-end">
          <span [ngClass]="{
                          'succes-trans': data.type === 'Repay',
                          'failed-trans': data.type === 'Withdraw'}">

            {{data.amount}}
            <mat-icon style="cursor: pointer; color: #F58BB3;;">keyboard_arrow_right</mat-icon>
           
          </span>
        
            
            
          <!--<mat-icon style="position: relative; top: 0.49rem;">keyboard_arrow_right</mat-icon>-->
        </div>
      </div>

    </div>
  </div>
  </div>


  </div>
</div>

<!--<div class="container-fluid m-0 p-0">

  <div class="curve-bg">

    <div class="row">
      <div class="col-sm-12">
        <mat-icon class="back-arrow" style="cursor: pointer;" [routerLink]="['/available-limit']">arrow_back</mat-icon>

      </div>
    </div>

    <div class="row ">
      <div class="col-md-4 offset-md-4">
        <h2 class="heading-title text-center">Transaction History</h2>

        <hr class="new1">

<div class="trans-details mt-1">
        <div class="p-2" *ngFor="let ab of payment_details | groupBy:'date'">
          <div class="mb-2">
            <label class="date-lable">
             {{ ab.key| TimeConvert }} </label>

            <div class="trans-box-1 mt-2 p-2 clearfix" *ngFor="let data of ab.value" style="cursor: pointer;"
              [routerLink]="['/transaction-details',data.id]">
              <div class="float-start p-2"> 15.10 <span class="trans-comment-repay">{{data.type}}</span></div>
              <div class="float-end">
                <span [ngClass]="{
                                'succes-trans': data.type === 'Repay',
                                'failed-trans': data.type === 'Withdraw'}">

                  {{data.amount}}

                </span>
                <mat-icon style="position: relative; top: 0.49rem;">keyboard_arrow_right</mat-icon>
              </div>
            </div>

          </div>
        </div>
        </div>

      </div>
    </div>
  </div>
</div>-->
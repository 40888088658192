<div class="container-fluid p-0">
    <header>
        <div class="otp-logo"></div>
    </header>

<div class="form-heading">
    Verify your mobile number
</div>
<div class="form-layout-otp">
  


    <form [formGroup]="pwaLoginForm"  autocomplete="off" novalidate>
    <div class="form-label pt-4">Enter OTP</div>
    <div style="display:flex; align-items: center; justify-content: center;">
        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
    </div>
        <div class="text-danger text-center p-2">
        <!-- <mat-error *ngIf="!otpCtrl.hasError('required') && otpCtrl.hasError('notOfRequiredLength')">
            OTP should be of size : {{otpMaxLength}}
            </mat-error> -->
            <mat-error *ngIf="!otpCtrl.hasError('required') && otpCtrl.hasError('invalidOTP')">
            Invalid OTP
            </mat-error>
            <mat-error *ngIf="otpCtrl.hasError('required')">
            OTP is <strong>required</strong>
            </mat-error>
        </div>
      <!--<p class="timer" *ngIf="showCountdown">Please wait <span id="timer">(00 :
        {{countdownCounter | number:'2.0'}})</span></p>-->


</form>
</div>


<div class="d-flex justify-content-center pt-2">
    <input (click)="verifyotp()" class="form-control form-btnn btn-sm" type="submit" value="Next">
</div>

<div class="pt-4">
<label class="d-flex justify-content-center">Did not Received the OTP?<span style="padding-left: 2px"><a (click)="ResendOtp()" style="color:#e43779;font-weight: 500; text-decoration: none; cursor: pointer;"> Resend OTP</a></span></label>
</div>

</div>


<!--<div class="container-fluid">
    <div class="row text-center">
        <div class="col-md-4 mx-auto">
            <h2 class="heading-title">Verify your mobile number</h2>

            <div class="mt-1">OTP has been send to your Mobile Number</div>

            <form [formGroup]="pwaLoginForm" autocomplete="off">
            <div class="row justify-content-center mt-3">

                <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>

                


                <div class="text-danger">
                  
                    <mat-error *ngIf="!otpCtrl.hasError('required') && otpCtrl.hasError('invalidOTP')">
                      Invalid OTP
                    </mat-error>
                    <mat-error *ngIf="otpCtrl.hasError('required')">
                      OTP is <strong>required</strong>
                    </mat-error>
                   
                  </div>-->

                  <!--<p class="timer" *ngIf="showCountdown">Please wait <span id="timer">(00 :
                    {{countdownCounter | number:'2.0'}})</span></p>-->
                    
            <!--</div>



            <div class="m-3">
                <input (click)="verifyotp()" class="form-control form-btn" type="button" value="Verify">
            </div>
            <label>Didn't Receive the OTP? </label>
            <div>
                <input (click)="ResendOtp()" class="mt-1" style="color:#e43779;font-weight: 500; text-decoration: none;" type="button" value="Resend">
                </div>

</form>
        </div>

    </div>
</div>-->
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  
constructor(public auth: AuthService,private router: Router,private apiService: ApiService,private _Activatedroute: ActivatedRoute) { }
// Form related fields
  // Subscriptions
timerSubscription: Subscription;
OTP_COUNTDOWN_SECONDS=60;
showCountdown = false;
countdownCounter = 0;

otpMaxLength = 6;
mobile_number:string;
mobileCtrl: FormControl = new FormControl('', [Validators.required]);
otpCtrl: FormControl = new FormControl('',[Validators.required]);
pwaLoginForm: FormGroup = new FormGroup({
  mobile: this.mobileCtrl,
  otp: this.otpCtrl
});

config = {
  allowNumbersOnly: true,
  length: 6,
  isPasswordInput: false,
  disableAutoFocus: false,
  placeholder: '',
  inputClass: 'form-control form-inputs',
  inputStyles: {
    'padding':0,
    'display': 'inline-block'
  }
};

  ngOnInit(): void {
    this.mobile_number = this._Activatedroute.snapshot.paramMap.get("mobile");
    this.mobileCtrl.setValue(this.mobile_number);

    //this.startCountdownCounter()

    if(this.auth.isAuthenticated())
    {
      this.getLoanDetails();
    }
  }
  

onOtpChange(otp)
{
  this.otpCtrl.setValue(otp)
}

startCountdownCounter() {
  this.showCountdown = true;
  this.timerSubscription = timer(200, 1000).pipe(take(this.OTP_COUNTDOWN_SECONDS)).subscribe(counter => {
    this.countdownCounter = this.OTP_COUNTDOWN_SECONDS - (counter + 1);
    if (this.countdownCounter === 0) {
      this.showCountdown = false;
    }
  });
}

ResendOtp()
{
  const requestLoad = {'mobile' : this.mobile_number}
  this.apiService.requestOtp(requestLoad).subscribe();
}
verifyotp()
{

  if (!this.pwaLoginForm.valid) {
    return false;
  }
  if (this.pwaLoginForm.value.otp.toString().length !== this.otpMaxLength) {
    this.mobileCtrl.setErrors({invalidOTP: true});
    return false;
  }
  const pwaRequest =  {
    mobile: this.pwaLoginForm.value.mobile,
    otp: this.pwaLoginForm.value.otp
  };

  this.apiService.verifyOtp(pwaRequest).subscribe((data:any) =>{
    
    if(data.code === "OK"){

    localStorage.setItem('token',data.auth.token);
   
    this.apiService.getProfile().subscribe((Userdata:any) =>{

      localStorage.setItem('mobile',Userdata.profile.mobile);
      })
     
      this.getLoanDetails();
  }
  })

}

getLoanDetails()
{
    this.apiService.fetchLoanDetailsForCondition().subscribe((loanDetails:any) =>{
      if (loanDetails) {
        let loan_check = loanDetails.loan
        let checkforFirstWithdraw= (loan_check.payments).filter(type => type.amount < 0);

        
       // console.log(loanDetails,checkforFirstWithdraw)

       // (loan_check.limit_product === true) && (loan_check.status === 'limit_approved') ||
        if (checkforFirstWithdraw.length > 0) {

          this.router.navigate(['/available-limit/']);
        }
        else{
          this.router.navigate(['/processingfee/']);
        }
      }
    });
}
}

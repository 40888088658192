import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-congo',
  templateUrl: './congo.component.html',
  styleUrls: ['./congo.component.css']
})
export class CongoComponent implements OnInit {

  reapy_amount:number;
  loanDetails:any
  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {

    this.apiService.currentAmount.subscribe(message => this.reapy_amount = message);

    if(this.reapy_amount == 0) {
      this.router.navigate(['/withdraw/']);
    }

  }

  Goback()
{

  this.apiService.fetchLoanDetails().subscribe((data:any) =>{
    
    if(data.code == "OK"){
      
      this.loanDetails = data.loan;

      let checkforFirstWithdraw= (this.loanDetails.payments).filter(type => type.amount < 0);
      //(this.loanDetails.limit_product === true) && (this.loanDetails.status === 'limit_approved') ||
        if ( checkforFirstWithdraw.length > 0) {
      
          this.router.navigate(['/available-limit/']);
        }
        else{
          this.router.navigate(['/credit/']);
        }
    }
  });



}
}

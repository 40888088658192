<div class="container-fluid">
    <div class="page-container">

        <div class="left-arrow">
          <a [routerLink]="['/transaction-history']" style="cursor: pointer;"><img src="../../../assets/Vector-3.svg"></a>
        </div>
        <div class="page-header">
          <h3>Transaction Details</h3>
        </div>

        <div class="d-flex justify-content-center pt-4">
            <img src="../../../assets/ArthLogo.svg">
         </div>

         <div class="text-center mt-2">
            {{ trans_type }}<span style="color: #EE3E80"> Arth</span>
         </div>

         <div class="text-center p-3">
            <span class="trans-symbol"> ₹</span>
            <span class="tran-amount">{{trans_amount}}</span>

         </div>

         <div class="box-model-trans mt-2">
            {{box_data}}
         </div>

         <div class="d-flex justify-content-between pt-4">
            <div class="d-flex flex-column">
              <div class="p-1 limit-title">Transaction Date</div>
              <div class="p-1 limit-title">Transaction ID</div>
            
             
             
            </div>
    
            <div class="d-flex flex-column">
              <div class="p-1 limit-value">{{converted_date}}</div>
              <div class="p-1 limit-value">{{trans_details?.transaction_id}}</div>
             
            </div>
    
          </div>


    </div>
</div>
<!--<div class="container-fluid m-0 p-0">

    <div class="curve-bg">

        <div class="row">
            <div class="col-sm-12">
                <mat-icon class="back-arrow" style="cursor: pointer;" [routerLink]="['/transaction-history']">arrow_back</mat-icon>

            </div>
        </div>

        <div class="row text-center">
            <div class="col-md-4 offset-md-4">

                <h2 class="heading-title">Detailed Transaction History</h2>

                <hr class="new1">

               
            </div>
        </div>


        <div style="background-color: #fce8d5; border-radius: 10px;" class="col-md-4 offset-md-4 p-4">
            <div class="row ">
                <div class="form-group col-md-6 mb-3">
                    <label class="trans-label">Transaction ID</label>
                    <div class="trans-input">{{trans_details?.transaction_id}}</div>
                </div>
                <div class="form-group col-md-6 mb-3">
                    <label class="trans-label">Transaction Amount</label>
                    <div class="trans-input"> {{trans_amount}}</div>
                </div>
                <div class="form-group col-md-6 mb-3">
                    <label class="trans-label">Transaction Date</label>
                    <div class="trans-input">{{trans_details?.transaction_date}}</div>
                </div>
                <div class="form-group col-md-6 mb-3 d-none">
                    <label class="trans-label">Transaction Status</label>
                    <div class="trans-input"></div>
                </div>

                <div class="form-group col-md-6 mb-3 d-none">
                    <label class="trans-label">Transaction Description</label>
                    <div class="trans-input"></div>
                </div>

                <div class="form-group col-md-6 mb-3">
                    <label class="trans-label">Mode of payment</label>
                    <div class="trans-input"></div>
                </div>

            </div>
        </div>
    </div>


</div>-->
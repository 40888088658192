<div class="container-fluid">
    <div class="page-container">
  
        <div class="left-arrow">
            <a [routerLink]="['/available-limit']" style="cursor: pointer;"><img src="../../../assets/Vector-3.svg"></a>
          </div>
          <div class="page-header">
            <h3>Repay</h3>
          </div>
    
          <div class="box-model mt-4">

            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column">
                <div class="p-1 limit-title" style="position: relative;top: 0.4rem;">Pay Interest</div>
              </div>
              <div class="d-flex flex-column">
                <div class="p-1 limit-value">₹ 1000  <span><mat-icon style="cursor: pointer; color: #F58BB3;position: relative;
                    top: 0.4rem;">keyboard_arrow_right</mat-icon></span></div>
              </div>
            
            </div>
        
              
          </div>
          <div class="m-4 text-center limit-title">How much do you want to repay?</div>


          <div style="position: relative; left: calc(50% - 70%/2);">
                    
            <input 
                          #input 
                          type="range" 
                          id="range" 
                         
                          [step]="params.step"
                          [min]="params.min"
                          [max]="params.max"
                          
                  >

              </div>
               
              <div class="d-flex justify-content-around pt-2">
                  <div class="col-4">
                      Min 1000
                  </div>
                  <div class="col-4">
                      Max 62000
                  </div>
              </div>


          <div class="limit-title text-center p-4">Move the pointer or type the amount.</div>

          <div class="d-flex justify-content-center p-4">
                       
            <input class="form-control credit-btn btn-sm" type="submit" value="Repay">
           
        </div>

    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
@Component({
  selector: 'app-repay',
  templateUrl: './repay.component.html',
  styleUrls: ['./repay.component.css']
})
export class RepayComponent implements OnInit {

  autoTicks = false;
  disabled = false;
  invert = false;
  max = 0;
  min = 1000;
  showTicks = true;
  step = 1000;
  thumbLabel = false;
  value = 0;
  vertical = false;
  tickInterval = 1;

  slider_value: number = 0;
  steps: number = 1000;

  params = {
    defaultValue: 0,
    min: this.min,
    max: this.max,
    step: this.steps
  };
  inputValue: number = this.params.defaultValue;
  interest_amount: number = 0;

  set_span_val: number = 0;
  stepsValidator(control: AbstractControl) {
    if (!control.value || control.value % this.steps !== 0) {
      return { error: true };
    }
    return null;
  }
  handleInputChange(e) {
    let target = e.target
    if (e.target.type !== 'range') {
      target = document.getElementById('range')
    }
    const min = target.min
    const max = target.max
    const val = target.value
    target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
  }
  constructor() { }

  ngOnInit(): void {
  }


  
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-congratulation',
  templateUrl: './congratulation.component.html',
  styleUrls: ['./congratulation.component.css']
})
export class CongratulationComponent implements OnInit {

  reapy_amount:number;
  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {

    this.apiService.currentAmount.subscribe(message => this.reapy_amount = message);

    if(this.reapy_amount == 0) {
      this.router.navigate(['/available-limit/']);
    }

  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
   production: false,
   apiUrl: 'https://sdk-api.staging.arthapp.in/v1/api/',
   //apiUrl: 'https://sdk-api.staging.happy-tech.in/v1/api',
   //apiUrl: 'https://sdk-api.staging.happy-tech.in/v1/api/demopartner',
   // pwaUrl: 'https://sdk-api.staging.happy-tech.in/v1/pwa',
   // pwaUrl: https://sdk-api.arthapp.in/v1/api/sessions/otp
   key_id: "rzp_test_YU2cX4IDZUfCPZ",
   secret_key: "l9Wfjce3Soyst9xxnTmXfANy",
   payment_gateway_url: "https://api.razorpay.com/v1/orders",
   staging: false,
   utilsApiUrl: 'https://sdk-api.arthapp.in/v1/utils_api/',
   genericApiUrl: 'https://sdk-api.arthapp.in/v1/generic/',
   defaultRoute: 'login',
   mixpanel_mswipe_key: 'c9f039056137650a66b64852c0a9f00c',
   mixpanel_truebalance_key: '35b72c868289cc35b00fcb610512580f',
   rzp_key: 'rzp_test_YU2cX4IDZUfCPZ',
   rzp_color_scheme: '#F37254',
   rapidapi_key: 'a8889b44d8msh6cb103f6e41d234p10ddc0jsn608e87c4d8fb',
   rapidapi_host: 'mailcheck.p.rapidapi.com',
   // pwaApiUrl: 'https://sdk-api.arthapp.in/v1/pwa',
   // pwaUrl: 'https://sdk.arthapp.in/'
   pwaApiUrl: 'https://sdk-api.staging.arthapp.in/v1/pwa',
   pwaUrl: 'https://sdk-api.staging.arthapp.in/v1/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupByPipe } from './groupby.pipe';
import { TimeconvertPipe } from './timeconvert.pipe';





@NgModule({
  declarations: [ 
    GroupByPipe,
    TimeconvertPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
   GroupByPipe,TimeconvertPipe
  ]
})
export class PipesModule { }


<div class="container-fluid">
    <div class="page-container">
  
        <div class="left-arrow">
            <a style="cursor: pointer;"><img src="../../../assets/Vector-3.svg" ></a>
        </div>

        <div class="d-flex justify-content-center p-4">
            <source src="../assets/Arth Withdraw Illustration.svg" style="height: 15rem">
         </div>

         <div class="heading-sorry p-2">Congratulations! </div>

         <div class="text-sorry"> ₹ {{reapy_amount}}</div>

        <div class="text-center limit-title"> Has been withdraw succesfully !</div>
        
        <div class="text-center limit-title p-5"> Your transaction ID <br> XXXXXXXXXXXXXXXXXXXXXXXX</div>

        <div class="d-flex justify-content-center">
                       
            <input [routerLink]="['/transaction-history']"  class="form-control sorry-btn btn-sm" type="button" value="View Transaction">
           
        </div>

    </div>
</div>
<!--<div class="container-fluid m-0 p-0">
    <div class="curve-bg">
        <div class="row text-center">
            <div class="col-md-4 offset-md-4">
                <img src="../../assets/correct.png" class="img-fluid mt-3" />

                <h1 class="heading-title mt-3 text-center" style="font-weight: 500;font-size: 2em;">Congratulations!</h1>

                <div class="mt-4">
                    <div class="mb-2">You have successfully availed </div>
                    <label class="heading heading-label">₹ {{reapy_amount}}</label>
                </div>

                <div style="margin-top: 3.2rem; margin-bottom: 3.2rem;">
                    Your transaction ID <br> XXXXXXXXXXXXXXXXXXXXXXXX

                </div>

                <div class="container mt-4">
                    <input (click)="Goback()" class="form-control form-btn-no-bg" type="button" value="View Line of Credit Details">
                </div>



            </div>
        </div>
    </div>
</div>-->
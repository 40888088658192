<div class="container-fluid">
  <div class="page-container">
      <div class="left-arrow">
        <a (click)="Goback()" style="cursor: pointer;"><img src="../../../assets/Vector-3.svg"></a>
      </div>
      <div class="page-header">
        <h3>Withdraw</h3>
      </div>

      <div class="d-flex justify-content-between pt-4">
        <div class="d-flex flex-column">
          <div class="p-1 limit-title">Valid Till</div>
          <div class="p-1 limit-title">Interest</div>
          <div class="p-1 limit-title">Processing Fees</div>
         
         
        </div>

        <div class="d-flex flex-column">
          <div class="p-1 limit-value">{{valid_date}}</div>
          <div class="p-1 limit-value">₹ {{interest_amount}} per month</div>
          <div class="p-1 limit-value"> {{loanDetails?.processing_fee_percent}}% + GST 18%</div>
        </div>

      </div>

      <div class="m-4 text-center limit-title">How much do you want to withdraw?</div>

      <form [formGroup]="WithdrawForm" (ngSubmit)="openBottomSheet()" autocomplete="off" novalidate>
        <div style="position: relative; justify-content: center;">
                    
                 <input 
                               #input 
                               type="range" 
                               id="range" 
                               [step]="params.step"
                               [min]="params.min"
                               [max]="params.max"
                               formControlName="amount"
                            
                       >
   
                   </div>
                    
                   <div class="d-flex justify-content-around p-2" style="width:100%">
                       <div class="col-4" style="padding: 0; margin-right:50%;">
                           Min {{min}}
                       </div>
                       <div class="col-4">
                           Max {{max}}
                       </div>
                   </div>

                  

                   <div class="d-flex align-items-center justify-content-center">
                    <div class="amount-box"><span class="rs-symbol">₹</span><span class="input-wrap">
                            <span class="width-machine" aria-hidden="true">{{set_span_val}}</span>
                            <input (mouseout)="keyFunc($event)" (mouseover)="keyFunc($event)"  (change)="keyFunc($event)" formControlName="text_amount" step="{{steps}}" min="{{min}}" max="{{max}}"  class="input-amount" type="number"></span> 
                        </div>
                    </div>
                    <!--<div class="text-danger text-center">Amount must be multiple of {{steps}}</div>-->


                    <div class="limit-title text-center p-4">Move the pointer or type the amount.</div>

                    <div class="d-flex justify-content-center p-4">
                       
                        <input [disabled]="!WithdrawForm.valid" class="form-control credit-btn btn-sm" type="submit" value="Withdraw">
                       
                    </div>

      </form>
  </div>
    <!--<div class="curve-bg">
        <div class="row">
            <div class="col-sm-12">
                <mat-icon class="back-arrow" style="cursor: pointer;" (click)="Goback()">arrow_back</mat-icon>

            </div>
        </div>
        <div class="row text-center">
            <div class="col-md-4 offset-md-4">
                <h2 class="heading-title mb-2">Withdraw</h2>

                
                <div style="font-size:16px;" class="pb-2">How much do you want to withdraw?</div>
                <form [formGroup]="WithdrawForm" (ngSubmit)="openBottomSheet()" autocomplete="off" novalidate>

              

               
                
                
                    <div  class="container pb-3">
                    
         <input 
                            #input 
                            type="range" 
                            id="range" 
                           
                            [step]="params.step"
                            [min]="params.min"
                            [max]="params.max"
                            formControlName="amount"
                    >

                </div>
                 
                <div class="row justify-content-around" style="line-height: 0;">
                    <div class="col-4">
                        Min {{min}}
                    </div>
                    <div class="col-4">
                        Max {{max}}
                    </div>
                </div>
                <div style="font-size:14px;" class="pb-2">Move the pointer or type the amount.</div>

                <div class="d-flex align-items-center justify-content-center mt-3">
                <div class="amount-box"><span class="rs-symbol">₹</span><span class="input-wrap">
                        <span class="width-machine" aria-hidden="true">{{set_span_val}}</span>
                        <input (mouseout)="keyFunc($event)" (mouseover)="keyFunc($event)"  (change)="keyFunc($event)" formControlName="text_amount" step="{{steps}}" min="{{min}}" max="{{max}}"  class="input-amount" type="number"></span> 
                    </div>
                </div>

                <div class="amount_label mx-auto mt-3">
              
                    

                  
                </div>
                <div class="text-danger">Amount must be multiple of {{steps}}</div>

            
           

                 
                
                <div class="mt-4">
                   
                    <label class="mb-1">Interest  ₹ {{interest_amount}} per month </label>
                    <div style="font-weight: 500;">Valid till {{valid_date}}</div>
                    <div class="mt-4">Processing Fees  {{loanDetails?.processing_fee_percent}}% + GST 18%</div>
                </div>
                <div class="container mt-4">
                    <input [disabled]="!WithdrawForm.valid" class="form-control form-btn" type="submit" value="Withdraw">
                </div>
                </form>
               

            </div>
        </div>
    </div>-->
</div>
import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DuePaymentComponent } from '../due-payment/due-payment.component';
import { environment } from '../../../environments/environment';
import { ErrordialogService } from 'src/app/error-dialog/errordialog.service';
@Component({
  selector: 'app-pay-amount',
  templateUrl: './pay-amount.component.html',
  styleUrls: ['./pay-amount.component.css']
})
export class PayAmountComponent implements OnInit {

  private Gateway_key = environment.key_id;

  constructor(private errorDialogService: ErrordialogService, private zone: NgZone, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private fb: FormBuilder, private apiService: ApiService, private bottomSheet: MatBottomSheet, private router: Router, private _bottomSheetRef: MatBottomSheetRef<PayAmountComponent>) { }

  PayForm: FormGroup;
  submitted = false;

  profile_data: any;

  success_trans: boolean = false;
  ngOnInit(): void {

    this.PayForm = this.fb.group({
      loanUid: [this.data.loan_id],
      transaction_id: ['timestamp'],
      transaction_type: ['REPAY'],
      customer_code: [''],
      amount: ['', [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
      ]]
    })

    this.apiService.getProfile().subscribe((userdata: any) => {

      if (userdata.code === "OK") {
        let profile_detail = userdata.profile;
        this.profile_data = userdata.profile;

        this.PayForm.controls['customer_code'].setValue(profile_detail.customer_code);

      }

    })
  }

  openBottomSheet(total_due): void {
    this._bottomSheetRef.dismiss();
    this.bottomSheet.open(DuePaymentComponent, {
      data: { 'total_due': total_due },
    });
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  redirect_url;
  get f() { return this.PayForm.controls }
  repay() {
    this.submitted = true;
    if (this.PayForm.invalid) {
      return;
    }

    if (this.PayForm.valid) {

      let today = new Date();

      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      let dateTime = date + ' ' + time;

      let order_id = Date.parse(dateTime)

      let formdata = this.PayForm.value;

      let forgatewayAmount = formdata.amount * 100;

      let options = {
        "key": this.Gateway_key, // Enter the Key ID generated from the Dashboard
        "amount": forgatewayAmount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": this.profile_data.name,
        "description": 'LOC--' + this.data.loan_id,
        "image": "",
        // "order_id": 'order_IYtGo5dvDof6ei',
        "handler": (response) => {

          this.handle_response(response);
        },
        "prefill": {
          "name": this.profile_data.name,
          "email": this.profile_data.email,
          "contact": this.profile_data.mobile
        },
        "notes": {
          "LoanID": 'LOC--' + this.data.loan_id
        },
        "theme": {
          "color": "#4c3196"
        }
      }



      let rzp1 = this.apiService.nativeWindow.Razorpay(options);
      rzp1.open();
    }
  }

  handle_response(response) {

    if (typeof response.razorpay_payment_id == 'undefined' || response.razorpay_payment_id < 1) {
      this._bottomSheetRef.dismiss();
      this.zone.run(() => {
        this.router.navigate(['/sorry']);
      });
    } else {
      this._bottomSheetRef.dismiss();


      this.zone.run(() => {
        this.apiService.loanRepaid(this.PayForm.value).subscribe((data: any) => {
          if (data.counts.processed > 0) {
            this.apiService.changeRepayAmount(this.PayForm.value.amount);

            this.router.navigate(['/congratulation']);
          }
          else {
            let error_data = {}
            this.errorDialogService.openDialog(error_data);
          }
        });


      })
    }

  }
}
